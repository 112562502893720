<template>
	<!-- 商品分类 -->
	<div class="commodityClassify">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" @click.stop="dialogVisible = true, form = { rate: 1 }">设置潮汐库存</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="商品图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_info.goods_thumb"
							:preview-src-list="[scope.row.goods_info.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="商品名称" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.goods_info.goods_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="当前库存" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.goods_info.stock }}</span>
					</template>
				</el-table-column>
				<el-table-column label="截止时间" prop="end_time" align="center"></el-table-column>
				<el-table-column label="冻结库存数" prop="freeze_stock" align="center"></el-table-column>
				<el-table-column label="执行频率" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.rate == 1">每{{ scope.row.rate_value }}小时恢复</span>
						<span v-else>截止时间恢复</span>
					</template>
				</el-table-column>
				<el-table-column label="恢复库存" prop="title" align="center">
					<template slot-scope="scope">
						<span>每次恢复{{ scope.row.restore_stock }}个</span>
					</template>
				</el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0" style="color: red;">自然结束</span>
						<span v-if="scope.row.status == 1">正常</span>
						<span v-if="scope.row.status == 2" style="color: red;">提前结束</span>
					</template>
				</el-table-column>
				<el-table-column label="备注" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.remark }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)"
							v-if="scope.row.status == 1">提前结束</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog title="新增" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="商品名称" required>
					<el-select v-model="form.goods_id" filterable remote reserve-keyword placeholder="请输入商品名称"
						:remote-method="remoteMethod" style="width: 100%;">
						<el-option v-for="item in goodsList" :key="item.id" :label="item.goods_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="冻结库存数">
					<el-input v-model="form.freeze_stock" placeholder="请输入冻结库存数"></el-input>
				</el-form-item>
				<el-form-item label="执行频率">
					<el-radio-group v-model="form.rate">
						<el-radio :label="1">每小时恢复</el-radio>
						<el-radio :label="2">截止时间恢复</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="执行间隔" v-if="form.rate == 1">
					<el-input v-model="form.rate_value" placeholder="请输入每隔X小时恢复一次"></el-input>
				</el-form-item>
				<el-form-item label="恢复库存">
					<el-input v-model="form.restore_stock" placeholder="请输入每次恢复库存的数量"></el-input>
				</el-form-item>
				<el-form-item label="截止时间">
					<el-date-picker v-model="form.end_time" type="datetime" placeholder="选择截止时间"
						value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" style="width: 100%;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="form.remark" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 弹窗
				goodsList: [],
				dialogVisible: false,
				form: {
					rate: 1
				}
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/goods/tide_stock_list`, {
					page: this.page,
					pageNum: this.pageNum,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 商品名称
			remoteMethod(e) {
				this.$http.post(`/erp/v1/goods/lst`, {
					pageNum: 100,
					key: e,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.goodsList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				if (!this.form.goods_id) {
					this.$message.error('请输入商品名称');
					return
				}
				if (!this.form.freeze_stock) {
					this.$message.error('请输入冻结库存数');
					return
				}
				if (this.form.rate == 1) {
					if (!this.form.rate_value) {
						this.$message.error('请输入执行间隔');
						return
					}
				} else {
					this.form.rate_value = ''
				}
				if (!this.form.restore_stock) {
					this.$message.error('请输入恢复库存');
					return
				}
				if (!this.form.end_time) {
					this.$message.error('请输入截止时间');
					return
				}
				this.$http.post(`/erp/v1/goods/set_tide_stock`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将提前结束该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/goods/ealy_over_tide_stock`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>